import React from "react";
import { Container } from "react-bootstrap";

const Requesthero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-contact mb-30">
      <Container>
        <div className="mb-30">
          <h2 className="banner-head mb-30">Request a Quote</h2>
        </div>
      </Container>
    </div>
  );
};

export default Requesthero;
