import React from "react";
import Requesthero from "../components/hero/Requesthero";
import Layout from "../components/Layout";
import RAQ1 from "../components/RAQ1";

const Request = () => {
  return (
    <Layout>
      <Requesthero />
      <RAQ1 />
      <h4 className="text-center">
        Someone from Young Home Inspections will follow up with you within 24
        hours
      </h4>
    </Layout>
  );
};

export default Request;
