import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Row, Col, Container } from "react-bootstrap";

const RAQ1 = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: {
        ok,
        msg,
      },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({
      submitting: true,
    });
    axios({
      method: "post",
      url: "https://formtest.cflmstest.com/api/contact/email.php",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Container>
      <div className="font-weight-bold">
        <h3> Please fill in the form below. </h3>{" "}
        <Form onSubmit={handleOnSubmit}>
          <Form.Row>
            <Form.Group controlId="formGridName" className="inputarea" required>
              <Form.Label> Your Name(required) </Form.Label>{" "}
              <Form.Control
                type="text"
                name="name1"
                placeholder="Jon Doe"
                size="lg"
              />
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row>
            <Form.Group
              controlId="formGridEmail"
              className="inputarea"
              required
            >
              <Form.Label> Your Email(required) </Form.Label>{" "}
              <Form.Control
                type="email"
                name="email1"
                placeholder="example@domain.com"
              />
            </Form.Group>{" "}
            <Form.Group
              controlId="formGridPhone"
              className="inputarea"
              required
            >
              <Form.Label> Phone Number </Form.Label>{" "}
              <Form.Control
                type="text"
                name="phone1"
                placeholder="(xxx)xxx-xxxx"
              />
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row>
            <Form.Group as={Col} className="mb-3" controlId="formGridAddress1" required>
              <Form.Label>Address</Form.Label>
              <Form.Control name="street1" placeholder="1234 Main St"/>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCity" required>
              <Form.Label>City</Form.Label>
              <Form.Control name="city1" />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridZip" required>
              <Form.Label>Zip</Form.Label>
              <Form.Control name="zip1" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="formGridName" className="inputarea" required>
              <Form.Label>
                {" "}
                What time frame are you looking to have the instpection?{" "}
              </Form.Label>{" "}
              <Form.Control
                type="text"
                name="timeframe1"
                placeholder="When do you need the inspection by?"
                size="lg"
              />
            </Form.Group>{" "}
          </Form.Row>{" "}
          <fieldset>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={3}>
                Type of Inspection
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="checkbox"
                  label="Full Comprehensive Home Inspection"
                  name="fullComprehensive1"
                  id="fullComprehensive"
                />
                <Form.Check
                  type="checkbox"
                  label="Wind Mitigation"
                  name="windMitigation1"
                  id="wind"
                />
                <Form.Check
                  type="checkbox"
                  label="4 Point"
                  name="fourPoint1"
                  id="fourpoint"
                />
                <Form.Check type="checkbox" label="Pool" name="pool1" id="pool" />
                <Form.Check
                  type="checkbox"
                  label="New Construction"
                  name="newConstruction1"
                  id="wind"
                />
                <Form.Check
                  type="checkbox"
                  label="Dock / Seawall"
                  name="dockSeawall1"
                  id="dock/seawall"
                />
                <Form.Check
                  type="checkbox"
                  label="Termite"
                  name="termite1"
                  id="termite"
                />
                <Form.Check
                  type="checkbox"
                  label="Mold / Air Quality"
                  name="MoldAQ1"
                  id="mold"
                />
                <Form.Check
                  type="checkbox"
                  label="Other"
                  name="other1"
                  id="other"
                />
              </Col>
            </Form.Group>
          </fieldset>
          <Button
            className="btn btn-secondary"
            variant="primary"
            type="submit"
            disabled={serverState.submitting}
          >
            Send{" "}
          </Button>{" "}
          {serverState.status && (
            <p className={!serverState.status.ok ? "errorMsg" : ""}>
              {" "}
              {serverState.status.msg}{" "}
            </p>
          )}{" "}
        </Form>{" "}
      </div>{" "}
    </Container>
  );
};

export default RAQ1;
